import React from "react";
import AnimationShowCase from "./components/AnimationShowCase";
import Banner from "./components/Banner";
import SignUpBanner from "./components/SignUpBanner";
import Testimonials from "../../components/landingPage/Testimonials";
import FinalActionBar from "./components/FinalActionBar";
import Footer from "@/components/landingPage/Footer";

import CurrentClientsCarousel from "@/components/landingPage/CurrentClientsCarousel";
import LatestJobs from "./components/LatestJobs";
import {
  candidateTestimonialFour,
  candidateTestimonialOne,
  candidateTestimonialThree,
  candidateTestimonialTwo,
  clientLogoDarkCodeCraft,
  clientLogoDarkGrappus,
  clientLogoDarkLicious,
  clientLogoLightCognitiveClouds,
} from "@/constants/media";

const Home = () => {
  return (
    <>
      <Banner />
      <CurrentClientsCarousel
        headingElement={
          <h1 className="text-lg text-center mb-[40px] lg:text-4xl md:text-2xl ">
            You Can Work At <span className="text-gradient-1">Companies</span>{" "}
            Like
          </h1>
        }
      />
      <LatestJobs />
      <AnimationShowCase />
      <SignUpBanner />
      <Testimonials
        contents={[
          {
            personName: "Chinmay Moghe",
            personTitle: "Sr. Frontend Developer",
            image: candidateTestimonialOne,
            employerLogo: clientLogoDarkLicious,
            description: `Thanks to Zelevate, I was able to find my dream job in the software engineering industry. Their personalized job matching process made it easy for me to find relevant job opportunities that aligned with my preferences. I highly recommend Zelevate to any job seeker looking for a transparent and efficient hiring process`,
          },
          {
            personName: "Vishal Rao",
            personTitle: "Flutter Engineer",
            image: candidateTestimonialTwo,
            employerLogo: clientLogoDarkGrappus,
            description: `Zelevate made my job search process so much easier and stress-free. I appreciated how they took the time to understand my career goals and preferences, and matched me with job opportunities that aligned with them. The pre-interviews and assessments also gave me an edge in the job market and helped me stand out to top companies. Thanks to Zelevate, I was able to find my dream job in no time!`,
          },
          {
            personName: "Gaurav Pal",
            personTitle: "Sr. Backend Developer",
            image: candidateTestimonialThree,
            employerLogo: clientLogoLightCognitiveClouds,
            description: `I was blown away by Zelevate's personalized approach to job matching. As someone who had been frustrated with the lack of suitable job opportunities on traditional job boards, I appreciated how Zelevate took the time to match me with roles that were a perfect fit for my skills and experience. The pre-interviews were also a huge time-saver, as I was able to bypass initial screening rounds with the company and get straight to the important conversations. I highly recommend Zelevate to any job seeker looking for a more effective way to find their next career move`,
          },
          {
            personName: "Ashika Shetty H",
            personTitle: "Sr. IOS Engineer",
            image: candidateTestimonialFour,
            employerLogo: clientLogoDarkCodeCraft,
            description: `I had a fantastic experience with Zelevate's hiring platform. As a job seeker, I appreciated how personalized the process was - I was matched with job opportunities that aligned with my skills and experience, and the pre-interviews made the interview process much more efficient. The Zelevate team was also incredibly responsive and helpful throughout the entire process. Thanks to Zelevate, I was able to find my dream job in a fraction of the time it would have taken with traditional job boards`,
          },
        ]}
      />
      <FinalActionBar />
      <Footer />

      {/*
       */}
    </>
  );
};

export default Home;
