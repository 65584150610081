import Link from "next/link";

import { RhButton, RhCard } from "@rhythm-ui/react";
import React from "react";

import { zelevateThemeBackgroundBanner } from "@/constants/media";

const SignUpBanner = () => {
  return (
    <div className="flex items-center justify-center p-0 lg:mx-[80px] lg:mt-20">
      <RhCard className="flex flex-col relative items-center justify-center border-0 w-full py-10 lg:p-20 px-[40px] section-bg gap-tm2">
        <h2 className="text-center z-[2]">
          Find your dream job with Zelevate today. Our personalized job search
          options, transparent hiring process, and AI-powered job matching
          technology make finding your dream job easier than ever.
        </h2>

        <div className="flex flex-col items-center gap-tsm z-[2]">
          <p className="text-center">
            Sign up now and take the first step towards a brighter future!
          </p>

          <div className="relative z-[1]">
            <Link href={"/signup"} passHref>
              <RhButton className="btn-bg cursor-pointer">
                Sign Up Now!
              </RhButton>
            </Link>
          </div>
        </div>
        <img
          className="absolute top-0 right-[-30%] object-cover p-0 m-0 h-full w-full scale-125 z-[1]"
          src={zelevateThemeBackgroundBanner}
          alt=""
        />
      </RhCard>
    </div>
  );
};

export default SignUpBanner;
