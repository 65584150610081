import { BASE_URL } from "@/constants";
import { JobFilterParamType } from "@/types/job";
import axios from "axios";

const publicAxiosInstance = axios.create();
publicAxiosInstance.defaults.baseURL = BASE_URL;

const publicJobRequirement = "job-requirement-public";

const publicSkills = "skills-public";
const publicIndustryDomains = "industry-domains-public";
const publicRoles = "roles-public";
const publicJobIds = "jr-ids-public";

export default {
  getJobs(params?: JobFilterParamType) {
    return publicAxiosInstance.get(`${publicJobRequirement}/`, { params });
  },
  getJobById(id: number | string) {
    return publicAxiosInstance.get(`${publicJobRequirement}/${id}/`);
  },

  // skills
  getSkills(data: { search?: string } = {}) {
    return publicAxiosInstance.get(`${publicSkills}/`, { params: data });
  },

  getIndustries(params?: { search?: string }) {
    return publicAxiosInstance.get(`${publicIndustryDomains}/`, { params });
  },

  getRoles(params?: { search?: string }) {
    return publicAxiosInstance.get(`${publicRoles}/`, { params });
  },
  getOpenJobIds() {
    return publicAxiosInstance.get(`${publicJobIds}/`);
  },
};
