import Image from "next/image";
import Script from "next/script";

import { Player } from "@lottiefiles/react-lottie-player";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

import { vectorSquares } from "@/constants/media";

interface ContentType {
  heading: any;
  paragraph: any;
  image: string;
  imageType: "image" | "lottie";
}

const ScrollAnimation = ({
  mainHeading,
  contentOne,
  contentTwo,
  contentThree,
}: {
  mainHeading: any;
  contentOne: ContentType;
  contentTwo: ContentType;
  contentThree: ContentType;
}) => {
  const [ImageNo, setImageNo] = useState(0);
  const handelViewportEnter = (value: number) => {
    setImageNo(value);
  };

  const pageScrollDivRef = useRef<null | Element>(null);

  const previousScrollValue = useRef(0);
  const firstLineRef = useRef<null | HTMLDivElement>(null);
  const secondLineRef = useRef<null | HTMLDivElement>(null);

  const handleScroll = () => {
    if (!firstLineRef.current || !secondLineRef.current) return;

    const firstLinePosition = firstLineRef.current.getBoundingClientRect().y;
    const secondLinePosition = secondLineRef.current.getBoundingClientRect().y;
    const halfWay = window.innerHeight / 2;

    if (!pageScrollDivRef.current) return;

    if (
      previousScrollValue.current <
      pageScrollDivRef.current.getBoundingClientRect().y * -1
    ) {
      if (firstLinePosition < halfWay && secondLinePosition > halfWay) {
        handelViewportEnter(1);
      } else if (firstLinePosition < halfWay && secondLinePosition < halfWay) {
        handelViewportEnter(2);
      }
    } else {
      //user scrolling up
      if (firstLinePosition < halfWay && secondLinePosition > halfWay) {
        handelViewportEnter(1);
      } else if (firstLinePosition > halfWay && secondLinePosition > halfWay) {
        handelViewportEnter(0);
      }
    }

    previousScrollValue.current =
      pageScrollDivRef.current?.getBoundingClientRect().y * -1;
  };

  useEffect(() => {
    const pageScrollDiv = document.querySelector(".app");
    pageScrollDivRef.current = pageScrollDiv;
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // draw line between first heading and last heading
  const firstHeading = useRef<null | HTMLHeadingElement>(null);
  const lastHeading = useRef<null | HTMLHeadingElement>(null);
  const contentContainer = useRef<null | HTMLDivElement>(null);

  const [firstHeadingAndContainerDiff, setFirstHeadingAndContainerDiff] =
    useState(0);
  const [secondHeadingAndContainerDiff, setSecondHeadingAndContainerDiff] =
    useState(0);

  useEffect(() => {
    const setLinePosition = () => {
      if (
        !contentContainer.current ||
        !firstHeading.current ||
        !lastHeading.current
      )
        return;

      setFirstHeadingAndContainerDiff(
        Math.floor(
          firstHeading.current.getBoundingClientRect().top -
            contentContainer.current.getBoundingClientRect().top
        )
      );

      setSecondHeadingAndContainerDiff(
        Math.floor(
          contentContainer.current.getBoundingClientRect().bottom -
            lastHeading.current.getBoundingClientRect().top
        )
      );
    };
    setLinePosition();

    window.addEventListener("resize", setLinePosition);

    return () => {
      window.removeEventListener("resize", setLinePosition);
    };
  }, []);

  return (
    <div className="bg-[#1B2434] relative overflow-clip">
      {mainHeading}

      <div className="relative z-[1] lg:h-[240vh] grid grid-cols-1 lg:grid-cols-2 px-tsm md:px-[40px] lg:px-[55px] ">
        <div
          className="h-full relative lg:pl-tm2 hidden lg:grid grid-rows-3"
          ref={contentContainer}
        >
          {/* green line start */}
          <div
            style={{
              top: `${firstHeadingAndContainerDiff + 25}px`,
              bottom: `${secondHeadingAndContainerDiff - 25}px`,
            }}
            className={`absolute -left-[1.9px] border-[1px] border-[#169f95]`}
          />
          {/* green line end */}

          <div className=" flex  items-center relative ">
            <div className="w-full relative overflow-visible pr-10 -mt-[200px]">
              <h1
                className="w-full text-lg text-white lg:mb-8 mb-tsm sm:text-4xl dot"
                ref={firstHeading}
              >
                {contentOne.heading}
              </h1>
              <p className="text-white">{contentOne.paragraph}</p>
            </div>
            <div className="absolute bottom-0" ref={firstLineRef} />
          </div>
          <div className=" flex  items-center relative">
            <div className="w-full relative overflow-visible pr-10">
              <h1 className="w-full text-lg text-white lg:mb-8 dot mb-tsm sm:text-4xl">
                {contentTwo.heading}
              </h1>
              <p className="text-white">{contentTwo.paragraph}</p>
            </div>
            <div className="absolute bottom-0" ref={secondLineRef}></div>
          </div>
          <div className=" flex items-center">
            <div className="w-full relative overflow-visible pr-10 -mt-[100px]">
              <h1
                className="w-full text-lg text-white dot lg:mb-8 mb-tsm sm:text-4xl"
                ref={lastHeading}
              >
                {contentThree.heading}
              </h1>
              <p className="text-white">{contentThree.paragraph}</p>
            </div>
          </div>
        </div>
        <div className="hidden h-[110%] lg:block">
          <div className="sticky z-1 top-[22%] flex justify-center h-screen">
            <Script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></Script>

            {ImageNo === 0 && (
              <>
                {contentOne.imageType === "image" && (
                  <motion.img
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className={`m-w-full absolute w-full  `}
                    src={contentOne.image}
                  ></motion.img>
                )}
                {contentOne.imageType === "lottie" && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.7 }}
                    className={`m-w-full absolute w-full`}
                  >
                    <Player
                      background="transparent"
                      speed={1}
                      autoplay
                      controls
                      src={contentOne.image}
                      keepLastFrame
                    />
                  </motion.div>
                )}
              </>
            )}
            {ImageNo === 1 && (
              <>
                {contentTwo.imageType === "image" && (
                  <motion.img
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className={`m-w-full absolute w-full  `}
                    src={contentTwo.image}
                  ></motion.img>
                )}
                {contentTwo.imageType === "lottie" && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.7 }}
                    className={`m-w-full absolute w-full`}
                  >
                    <Player
                      background="transparent"
                      speed={1}
                      autoplay
                      controls
                      src={contentTwo.image}
                      keepLastFrame
                    />
                  </motion.div>
                )}
              </>
            )}
            {ImageNo === 2 && (
              <>
                {contentThree.imageType === "image" && (
                  <motion.img
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className={`m-w-full absolute w-full  `}
                    src={contentThree.image}
                  ></motion.img>
                )}
                {contentThree.imageType === "lottie" && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.7 }}
                    className={`m-w-full absolute w-full`}
                  >
                    <Player
                      background="transparent"
                      speed={1}
                      autoplay
                      controls
                      src={contentThree.image}
                      keepLastFrame
                    />
                  </motion.div>
                )}
              </>
            )}
          </div>
        </div>

        <div className="block lg:hidden pb-txl">
          {/* content one */}
          {contentOne.imageType === "image" && (
            <img
              className={`m-w-full w-full my-tsm mb-tm2`}
              src={contentOne.image}
            ></img>
          )}
          {contentOne.imageType === "lottie" && (
            <div className={`m-w-full absolute w-full`}>
              <Player
                background="transparent"
                speed={1}
                autoplay
                controls
                src={contentOne.image}
                keepLastFrame
              />
            </div>
          )}
          <h2 className="text-white mb-tsm">{contentOne.heading}</h2>
          <p className="sm:body-small md:body text-white mb-t3xl">
            {contentOne.paragraph}
          </p>
          {/* content two  */}
          {contentTwo.imageType === "image" && (
            <img
              className={`m-w-full w-full my-tsm mb-tm2`}
              src={contentTwo.image}
            ></img>
          )}
          {contentTwo.imageType === "lottie" && (
            <div className={`m-w-full w-full`}>
              <Player
                background="transparent"
                speed={1}
                autoplay
                controls
                src={contentTwo.image}
                keepLastFrame
              />
            </div>
          )}

          <h2 className="text-white mb-tsm">{contentTwo.heading}</h2>
          <p className="sm:body-small md:body text-white mb-t3xl">
            {contentTwo.paragraph}
          </p>

          {/* content three */}
          {contentThree.imageType === "image" && (
            <img
              className={`m-w-full w-full my-tsm mb-tm2`}
              src={contentThree.image}
            ></img>
          )}
          {contentThree.imageType === "lottie" && (
            <div className={`m-w-full w-full`}>
              <Player
                background="transparent"
                speed={1}
                autoplay
                controls
                src={contentThree.image}
                keepLastFrame
              />
            </div>
          )}

          <h2 className="text-white mb-tsm">{contentThree.heading}</h2>
          <p className="sm:body-small md:body text-white mb-tm2">
            {contentThree.paragraph}
          </p>
          {/*  */}
        </div>
      </div>

      <div className="absolute overflow-hidden top-0 left-0">
        <Image
          src={vectorSquares}
          layout="fixed"
          width={"2233"}
          height={"374"}
        ></Image>
      </div>
      <div className="absolute top-0 left-[-3%] w-48 h-48 rounded-full footer-gradient-1">
        {" "}
      </div>

      <div className="absolute bottom-0 right-[-3%] w-48 h-48 rounded-full footer-gradient-1">
        {" "}
      </div>
      {/* <div className="mb-20"></div> */}
    </div>
  );
};

export default ScrollAnimation;
