import React from "react";

import {
  candidateScollOne,
  candidateScollThree,
  candidateScollTwo,
} from "@/constants/media";

import ScrollAnimation from "@/components/landingPage/ScrollAnimation";

// import { motion } from "framer-motion";

const AnimationShowCase = () => (
  <ScrollAnimation
    mainHeading={
      <h1 className="relative z-[1] w-2/3 mb-tm2 lg:w-full px-10 lg:px-20 lg:pt-20 pt-10">
        <span className="text-white"> Features & Benefits</span>
      </h1>
    }
    contentOne={{
      heading: <>Personalized Job Matches Based on Your Preferences</>,
      paragraph: (
        <>
          Zelevate offers personalized job search options based on your unique
          preferences. We take into account your desired salary, work hours, and
          remote work options to deliver job opportunities that match your
          needs. With Zelevate, you can trust us to have your best interests in
          mind throughout your job search
        </>
      ),
      image: candidateScollOne,
      imageType: "image",
    }}
    contentTwo={{
      heading: <>Unlock a World of Opportunities with Zelevate Assessment</>,
      paragraph: (
        <>
          Stand out to top companies by going through our standardized
          assessments and interview process. By showcasing your skills and
          qualifications through Zelevate, you increase your chances of getting
          noticed by leading employers and decrease the number of interview
          rounds required. Let us help you take your career to the next level
        </>
      ),
      image: candidateScollTwo,
      imageType: "image",
    }}
    contentThree={{
      heading: <>Trusted by Top Companies</>,
      paragraph: (
        <>
          Join the ranks of the best with Zelevate. Our platform is trusted by
          leading employers to find top talent like you
        </>
      ),
      image: candidateScollThree,
      imageType: "image",
    }}
  />
);

export default AnimationShowCase;
