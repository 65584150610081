import mixpanel, { Dict } from "mixpanel-browser";

class AnalyticsLog {
  constructor() {
    this.init();
  }

  private init() {
    // Initialise mixpanel
  }

  static register() {
    // Register event properties
  }

  static track(event_name: string, properties?: Dict | undefined) {
    mixpanel.track(event_name, properties);
  }

  // Generate analytics properties for appending as url parameters
  static generateAnalyticsProperties(params: Record<string, string>) {
    // Generate analytics properties
    const pre = `analytics_`;

    return Object.keys(params).reduce(
      (a: Record<string, string>, c) => ((a[`${pre}${c}`] = params[c]), a),
      {}
    );
  }
}

export default AnalyticsLog;
