import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import {
  RhButton,
  RhDivider,
  RhDrawer,
  RhIcon,
  RhListContainer,
  RhListItem,
} from "@rhythm-ui/react";
import classNames from "classnames";
import { motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";

import { LOCAL_STORAGE, USER_ROLE } from "@/constants";
import {
  iconBriefcase,
  iconDocumentOutlined,
  iconTwoPesonOutlined,
  vectorSquares,
  zelevateWordLogoWhiteText,
} from "@/constants/media";
import mixPanelEventName from "@/constants/mixPanelEventName";

import AnalyticsLog from "@/helpers/AnalyticsLog";

import CustomIcon from "@/components/utils/CustomIcon";

const Banner = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [scrollY, setScrollY] = useState(0);

  const onScroll = useCallback(() => {
    setScrollY(window.pageYOffset);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      return window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const router = useRouter();

  const onLoginAsCandidateHandler = () => {
    localStorage.setItem(
      LOCAL_STORAGE.LAST_LOGIN_USER_TYPE,
      USER_ROLE.CANDIDATE
    );
    router.push("/login");
  };

  return (
    <div className="bg-[#1B2434] border border-[#1B2434] border-r-0 border-l-0 border-b-0 relative overflow-clip">
      <nav
        className={classNames(
          "h-20 fixed top-0 w-full flex items-center  zelevate-container justify-center text-lg shrink-0 z-[8]",
          {
            "bg-[#1B2434]": scrollY > 20,
          }
        )}
      >
        <RhDrawer
          isOpen={isDrawerOpen}
          variant="temporary"
          className=""
          backdrop={true}
          position="right"
          closeOnOutsideClick={true}
        >
          <div className="flex flex-col justify-between h-full bg-[#1B2434] ">
            <div>
              <div className="flex items-center justify-between p-5">
                <div
                  className="flex w-2/3 cursor-pointer"
                  onClick={() => setIsDrawerOpen(false)}
                >
                  <Link href="/" passHref>
                    <Image
                      src={zelevateWordLogoWhiteText}
                      width="144"
                      height="48"
                      alt="logo"
                      className="cursor-pointer"
                      layout="fixed"
                    />
                  </Link>
                </div>
                <RhIcon
                  icon={"material-symbols:close-rounded"}
                  className="text-2xl text-white cursor-pointer"
                  onClick={() => setIsDrawerOpen(false)}
                />
              </div>

              <RhDivider width="sm" className="" />

              <div className="flex flex-col gap-3">
                <RhListContainer>
                  <RhListItem>
                    <Link href="/jobs" passHref>
                      <RhButton
                        onClick={() => {
                          AnalyticsLog.track(mixPanelEventName.JOBS_CLICK);
                        }}
                        className="w-full text-white"
                        layout="link"
                      >
                        Jobs
                      </RhButton>
                    </Link>
                  </RhListItem>
                  <RhListItem>
                    <Link href="/referral" passHref>
                      <RhButton
                        onClick={() => {
                          AnalyticsLog.track(
                            mixPanelEventName.REFER_AND_EARN_CLICK
                          );
                        }}
                        className="w-full text-white"
                        layout="link"
                      >
                        Refer & Earn
                      </RhButton>
                    </Link>
                  </RhListItem>
                  <RhListItem>
                    <Link href="/employer" passHref>
                      <RhButton
                        onClick={() =>
                          AnalyticsLog.track(
                            mixPanelEventName.FOR_EMPLOYERS_CLICK
                          )
                        }
                        className="w-full text-white"
                        layout="link"
                      >
                        For Employers
                      </RhButton>
                    </Link>
                  </RhListItem>
                  <RhListItem className="mb-tsm">
                    <Link href="/login" passHref>
                      <RhButton
                        onClick={() =>
                          AnalyticsLog.track(mixPanelEventName.LOGIN_CLICK)
                        }
                        className="w-full"
                        layout="outline"
                      >
                        Login
                      </RhButton>
                    </Link>
                  </RhListItem>
                  <RhListItem>
                    <Link href={"/signup"} passHref>
                      <RhButton
                        onClick={() =>
                          AnalyticsLog.track(
                            mixPanelEventName.CREATE_YOUR_PROFILE_CLICK
                          )
                        }
                        className="w-full btn-bg"
                      >
                        Create Your Profile
                      </RhButton>
                    </Link>
                  </RhListItem>
                </RhListContainer>
              </div>
            </div>
          </div>
        </RhDrawer>
        <div className="flex items-center justify-between w-full">
          <div className="flex cursor-pointer w-36">
            <Link href="/" passHref>
              <Image
                src={zelevateWordLogoWhiteText}
                width="144"
                height="48"
                alt="logo"
                layout="fixed"
                className="cursor-pointer"
              />
            </Link>
          </div>
          <div className="flex gap-4 lg:basis-[70%] justify-end items-center">
            <div className="items-center justify-center hidden gap-8 text-base font-normal lg:flex">
              <Link href="/jobs" passHref>
                <a
                  className="no-underline text-white"
                  data-testid="banner-JOBS"
                  onClick={() => {
                    AnalyticsLog.track(mixPanelEventName.JOBS_CLICK);
                  }}
                >
                  <span>Jobs</span>
                </a>
              </Link>
              <Link href="/referral" passHref>
                <a
                  className="no-underline text-white"
                  onClick={() => {
                    AnalyticsLog.track(mixPanelEventName.REFER_AND_EARN_CLICK);
                  }}
                >
                  <span>Refer & Earn</span>
                </a>
              </Link>

              <Link href="/employer" passHref>
                <a
                  className="no-underline text-white"
                  onClick={() => {
                    AnalyticsLog.track(mixPanelEventName.FOR_EMPLOYERS_CLICK);
                  }}
                >
                  <span>For Employers</span>
                </a>
              </Link>

              <div className="flex gap-tsm">
                <RhButton
                  // className="text-white border-white"
                  layout="outline"
                  onClick={() => {
                    onLoginAsCandidateHandler();
                    AnalyticsLog.track(mixPanelEventName.LOGIN_CLICK);
                  }}
                >
                  Login
                </RhButton>

                <Link href={"/signup"} passHref>
                  <RhButton
                    className="btn-bg"
                    onClick={() => {
                      AnalyticsLog.track(
                        mixPanelEventName.CREATE_YOUR_PROFILE_CLICK
                      );
                    }}
                  >
                    {" "}
                    Create Your Profile
                  </RhButton>
                </Link>
              </div>
            </div>

            <RhIcon
              icon={"charm:menu-hamburger"}
              className="text-2xl text-white cursor-pointer sm:block lg:hidden"
              onClick={() => {
                setIsDrawerOpen(true);
              }}
            />
          </div>
        </div>
      </nav>

      {/* ______________________ */}
      <div className="relative z-[7]">
        <div className="grid grid-cols-1 gap-tsm lg:grid-cols-2  lg:mt-[70px] md:pt-10 lg:pt-20 py-tsm lg:pl-20 md:pl-10 pl-tsm">
          <div className="flex items-start justify-center">
            <div className="flex flex-col gap-tsm">
              <div className="flex flex-col gap-tsm mt-t3xl lg:mt-0">
                <h3 className="text-gradient-1">#ElevateWithZelevate</h3>
                <h1 className="text-white mr-tsm md:mr-0">
                  Your <span className="text-gradient-1">One Stop Shop</span>{" "}
                  for Finding the Perfect Software Engineering Job
                </h1>
              </div>

              <p className="mb-tm2 text-secondary-200 mr-tsm md:mr-tm2">
                Get matched with top job opportunities and experience an
                efficient, personalized, and stress-free hiring process from
                start to finish.
              </p>

              <div className="flex flex-col md:flex-row gap-tsm mr-tsm md:mr-tm2 md:max-w-[350px]">
                <RhButton
                  className="w-full btn-bg"
                  onClick={() => router.push("/signup")}
                >
                  Create Your Profile
                </RhButton>

                <RhButton
                  className="w-full"
                  layout="outline"
                  onClick={() => router.push("/jobs")}
                >
                  Browse Jobs
                </RhButton>
              </div>

              {/* in out */}
              <div className="hidden lg:block">
                <div className="grid grid-cols-3 my-t4xl justify-between">
                  <div className="flex flex-col ">
                    <p className="h-txl w-txl rounded-[4px] flex justify-center items-center bg-greadient-1 mb-tnano">
                      <CustomIcon icon={iconTwoPesonOutlined} />
                    </p>

                    <h2 className="text-white mt-0">1M+</h2>
                    <p className="text-white">Candidate Signups</p>
                  </div>

                  <div className="flex flex-col ">
                    <p className="h-txl w-txl rounded-[4px] flex justify-center items-center bg-greadient-1 mb-tnano">
                      <CustomIcon icon={iconDocumentOutlined} />
                    </p>

                    <h2 className="text-white">100+</h2>
                    <p className="text-white"> Curated Jobs for you</p>
                  </div>

                  <div className="flex flex-col ">
                    <p className="h-txl w-txl rounded-[4px] flex justify-center items-center bg-greadient-1 mb-tnano">
                      <CustomIcon icon={iconBriefcase} />
                    </p>

                    <h2 className="text-white">200+</h2>
                    <p className="text-white">Successful Placements</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <motion.div
            initial={{ z: -50, x: -10, y: 10, opacity: 0 }}
            animate={{ z: 0, x: 0, y: 0, opacity: 1 }}
            transition={{
              type: "just",
              delay: 0.7,
            }}
            className="flex flex-col  items-center justify-center gap-tm2 mr-tsm mt-tsm lg:mt-0"
          >
            <div className="bg-greadient-1 rounded-md flex items-center p-tm2 w-full md:w-full lg:w-[397px]">
              <h1 className="text-gradient-1 mr-tsm">1.</h1>
              <h2 className="text-white">Build Your Profile</h2>
            </div>
            <div className="bg-greadient-1 rounded-md flex items-center p-tm2 w-full md:w-full lg:w-[397px]">
              <h1 className="text-gradient-1 mr-tsm">2.</h1>
              <h2 className="text-white">Take Zelevate Assessments</h2>
            </div>
            <div className="bg-greadient-1 rounded-md flex items-center p-tm2 w-full md:w-full lg:w-[397px] md:mb-tsm">
              <h1 className="text-gradient-1 mr-tsm">3.</h1>
              <h2 className="text-white">Companies Apply For You</h2>
            </div>

            {/* <div className="flex justify-center h-fit border-2">
              <img
                src="https://res.cloudinary.com/cdmnk/image/upload/v1677231619/zelevate/to4qpdn79q5jjyamuucb.png"
                className=""
              ></img>
            </div> */}
          </motion.div>
        </div>
        {/* Counters */}

        {/* COUNTERS FOR MOCILE SCREEN */}
        <div className=" lg:hidden lg:px-20 md:px-10 p-tsm">
          <div className="grid grid-cols-3 mb-txl md:mb-t4xl justify-between">
            <div className="flex flex-col ">
              <p className="h-txl w-txl rounded-[4px] flex justify-center items-center bg-greadient-1 mb-tnano">
                <CustomIcon icon={iconTwoPesonOutlined} />
              </p>

              <h2 className="text-white mt-0">1M+</h2>
              <p className="text-white">Candidate Signups</p>
            </div>

            <div className="flex flex-col ">
              <p className="h-txl w-txl rounded-[4px] flex justify-center items-center bg-greadient-1 mb-tnano">
                <CustomIcon icon={iconDocumentOutlined} />
              </p>

              <h2 className="text-white">100+</h2>
              <p className="text-white">Curated Jobs for you</p>
            </div>

            <div className="flex flex-col ">
              <p className="h-txl w-txl rounded-[4px] flex justify-center items-center bg-greadient-1 mb-tnano">
                <CustomIcon icon={iconBriefcase} />
              </p>

              <h2 className="text-white">200+</h2>
              <p className="text-white">Successful Placements</p>
            </div>
          </div>
          <div className=""></div>
        </div>
      </div>

      <div className="absolute overflow-hidden -top-2 -left-2">
        <Image
          priority
          src={vectorSquares}
          layout="fixed"
          width={"2233"}
          height={"374"}
        ></Image>
      </div>

      <div className="absolute bottom-0 right-0 w-48 h-48 rounded-full footer-gradient-1"></div>
      <div className="absolute bottom-0 left-0 w-48 h-48 rounded-full footer-gradient-1"></div>
    </div>
  );
};

export default Banner;
