import { RhButton } from "@rhythm-ui/react";
import Link from "next/link";
import React from "react";

const Footer = () => {
  return (
    <>
      {/* upper footer */}

      <div className="relative overflow-hidden px-tsm md:px-[40px] lg:px-20 py-10 md:py-[72px] bg-[#1B2434]">
        <div className="relative z-[1]  overflow-hidden ">
          <div className="relative grid justify-between grid-cols-1 md:grid-cols-2 gap-tsm md:gap-t3xl">
            <div className="flex flex-col gap-tsm">
              <h2 className="text-white">
                Spread the word and Earn Rewards! Refer a friend to Zelevate and
                win amazing prizes and cash rewards today!
              </h2>
              <p className="text-white">*Terms & Conditions apply</p>
              <Link href={"/referral"} passHref>
                <RhButton className="btn-bg self-start">Refer & Earn</RhButton>
              </Link>
            </div>

            <div className="flex flex-col items-center pl-0 my-auto lg:flex-row gap-tsm lg:pl-t3xl"></div>
          </div>

          {/* <h1>Hello</h1></div> */}
          {/* <div className="absolute bottom-0 right-0 z-10 w-20 h-20 footer-gradient-1"></div> */}
        </div>
        <div className="absolute top-[-10%] left-[-3%] z-440 w-48 h-48 rounded-full footer-gradient-1"></div>
        <div className="absolute bottom-[-10%] hidden lg:block right-[-3%] z-44 w-48 h-48 rounded-full footer-gradient-1"></div>
        {/* <RhDivider /> */}
      </div>

      {/* lower footer */}
    </>
  );
};

export default Footer;
