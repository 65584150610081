import Link from "next/link";

import { RhButton, RhCard, RhCarousel, RhCarouselItem } from "@rhythm-ui/react";
import React, { useEffect, useMemo, useState } from "react";

import { JobType } from "@/types/job";

import publicApi from "@/services/publicApi";

import useWindowSize from "@/hooks/useWindowSize";

const LatestJobs = () => {
  const { width: windowSize } = useWindowSize();

  const numberOfTile = useMemo(() => {
    if (!windowSize) return 3;
    if (windowSize < 640) return 1;
    else if (windowSize < 950) return 2;
    else if (windowSize < 1290) return 3;
    else if (windowSize < 1600) return 4;
    return 5;
  }, [windowSize]);

  // jobs from backend
  const [jobsList, setJobsList] = useState([] as JobType[]);

  const loadJobList = async () => {
    try {
      const res = await publicApi.getJobs({ top: 5 });
      setJobsList(res.data.results);
    } catch (error) {
      console.error("error loading jobs");
      // posthog
    }
  };

  useEffect(() => {
    loadJobList();
  }, []);

  if (!jobsList.length) return null;

  return (
    <div className="py-[80px]">
      <h1 className="text-center">
        Latest Jobs <span className="text-gradient-1">Opportunties</span>
      </h1>

      <div className="relative mx-auto my-[40px] w-full">
        <div className="companies-slider">
          <RhCarousel
            slidesPerView={numberOfTile}
            autoplay
            loop
            spaceBetween={2}
          >
            {jobsList.map((job, idx) => {
              return (
                <RhCarouselItem key={idx}>
                  <JobCard job={job} />
                </RhCarouselItem>
              );
            })}
          </RhCarousel>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center gap-tsm sm:flex-row">
        <p>Did not find the job you’re looking for?</p>
        <Link href={"/jobs"} passHref>
          <RhButton layout="outline">Look Here!</RhButton>
        </Link>
      </div>
    </div>
  );
};

export default LatestJobs;

const JobCard = ({ job }: { job: JobType }) => {
  return (
    <RhCard className="flex h-[136px] w-[296px] rounded-md p-tm2 shadow-none">
      <div className="mr-tsm flex h-[88px] w-[88px] items-center justify-between rounded-md border px-tnano">
        {job.company?.logo && <img src={job.company.logo} className="w-full" />}
      </div>
      <div className="col-span-7 flex flex-col justify-center">
        <h3>{job.title}</h3>
        <p className="text-light">{job.company?.name}</p>
      </div>
    </RhCard>
  );
};
